<template>
  <div class="nav_title">
    <div class="name">
      <slot></slot>

    </div>
  </div>
</template>

<script>
  export default {
    name: "SubTitle"
  }
</script>

<style scoped>

</style>